@font-face {
  font-family: 'ptsans';
  src: url('../fonts/ptsans-narrowbold-08.woff2') format('woff2'),
       url('../fonts/ptsans-narrowbold-08.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir-roman';
  src: url('../fonts/Avenir-Roman.woff2') format('woff2'),
       url('../fonts/Avenir-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'avenir-medium';
  src: url('../fonts/avenir-medium.woff2') format('woff2'),
       url('../fonts/avenir-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pfdin';
  src: url('../fonts/DIN-Regular.woff2') format('woff2'),
       url('../fonts/DIN-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}