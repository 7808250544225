@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "" !important;
  }
}

// override Drupal clearfix stlyes
.clearfix {
  @include clearfix;
}
