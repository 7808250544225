/*  Remove List Styles
*
*   No Params
*
*   Example Usage:
*
*     ul {
*       @include no-bullet;
*     }
*
*/
@mixin no-bullet {
  list-style: none;
  padding: 0;
}

@mixin add-bullet {
  list-style: disc outside none;
  margin: rem(24) rem(30) rem(30) rem(40);

  > li {
    @include margin(rem(15) null);

    @include mq($tablet) {
      @include margin(rem(20) null);
    }

  }// >li

}

@mixin border-list {

  > ul {

    > li {
      @include clearfix;
      border-bottom: solid 1px $gallery;
      @include margin(rem(20) null);
      @include padding(0 null rem(20));

      @include mq($desktop) {
        @include margin(rem(24) null);
        @include padding(0 null rem(24));
      }

      @include mq($desktop-plus) {
        @include margin(rem(30) null);
        @include padding(0 null rem(30));
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .image-left {

        @include mq($tablet) {
          padding-bottom: 0;
        }

      }

    }//> li

  }//> ul

}//@include border-list
