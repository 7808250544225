// Site specific button mixins
@mixin button () {
  display: inline-block;
  height: auto;
  padding: rem(15) rem(25);
  font-family: $pt-sans;
  font-weight: bold;
  text-transform: uppercase;
  background: $japonica;
  color: $white;
  text-align: center;
  @include font(18);
  line-height: 1;
  background-clip: padding-box;
  border: 0;

  @include hover {
    text-decoration: none;
    background-color: lighten($japonica, 5%);
  }

}
