@mixin centered($axis: null) {
  position: absolute;
  @if ($axis == 'x') {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  } @else if ($axis == 'y') {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  } @else {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}


@mixin vertical-align-flex($inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-direction: column;
  justify-content: center;
}
