figure {

  figcaption {
    margin: rem(12) 0;
  }

  @include mq($tablet) {
    margin: rem(24) 0;
  }

  @include mq($desktop-plus) {
    margin: rem(34) 0;
  }

  img {
    max-width: 100%;
  }

}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  &.tv {
    padding-bottom: 75%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}//.video-container

