.social-media-icons {

  ul {
    display: flex;
    @include no-bullet;

    > li {
      margin: 0 0 0 rem(20);

      &:first-child {
        margin-left: 0;
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rem(10) 0 0;
        width: 40px;
        height: 40px;

        .name {
          @include visually-hidden;
        }

        @include hover {
          color: $denim;
          text-decoration: none;
        }

        &.fb {
          @include icon(40px, 40px, 'social-fb-white.svg');
        }

        &.ig {
          @include icon(40px, 40px, 'social-ig-white.svg');
        }

        &.yt {
          @include icon(40px, 40px, 'social-yt-white.svg');
        }

      }// >a

      &:last-child {

        a {
          padding-right: 0;
        }

      }

    }// > li

  }

}//.social-media-icons
