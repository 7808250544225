.interview {

  header {
    @include mq($mobile) {
      min-height: 175px;
    }
  }

  h1 {
    text-align: center;
    //text-decoration: underline;
    color: $nepal;
    text-transform: uppercase;
    font-family: $pt-sans;
    font-size: 20px;
    letter-spacing: 0.025em;
  }

  h3 {
    text-align: center;
    color: $nepal;
    text-transform: uppercase;
    font-family: $avenir-medium;
    font-size: 16px;
    letter-spacing: 0.025em;
    font-weight: bold;
    margin-top: 26px;
    margin-bottom: 10px;
  }

  figure {
    margin-bottom: 20px;
  }

  article p {
    text-align: center;
    font-family: times;
    @include font(15, 20);
    margin-top: 10px;

    @include mq($tablet) {
      padding-left: 10%;
      padding-right: 10%;
    }

    @include mq($desktop) {
      padding-left: 22%;
      padding-right: 22%;
    }
  }

}