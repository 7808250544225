header {
  @include layout-container;
  @include padding(24px null 0);
  position: relative;
  min-height: 100px;

  @include mq($tablet) {
    @include padding(60px null 0);
    min-height: 230px;
  }

  @include mq($desktop) {
    @include padding(80px null 0);
    min-height: 260px;
  }

  .top-row {
    display: flex;
  }

  .logo {
    display: inline-block;
    margin-right: auto;
    color: $cameo;
    font-family: $pfdin;
    font-size: 32px;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: -0.025em;

    &:hover {
      text-decoration: none;
    }

    @include mq($desktop) {
      margin-left: 100px;
    }

    h1 {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0;
    }
  }


  .description {
    margin-bottom: 0;
    margin-top: 10px;

    @include mq($desktop) {
      padding-left: 100px;
    }

    p {
      @include font(14, 20);

      @include mq($mobile) {
        @include font(13, 18);
      }

    }
  }

}
