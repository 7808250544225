// Displays chevrons (or arrows) using CSS instead of images
// Options include $size, $thickness, $color, and $direction of arrow

@mixin chevron($size: null, $thickness: null, $color: null, $direction: null) {
  display: inline-block;
  height: $size;
  width: $size;
  border-width: $thickness 0 0 $thickness;
  border-color: $color;
  border-style: solid;

  @if $direction == 'right' {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  @else if $direction == 'down' {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  @else if $direction == 'left' {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  @else if $direction == 'up' {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
