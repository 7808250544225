.product-layout {

  @include mq($tablet) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 1.6fr 1fr;
    grid-template-rows: auto 1fr;

    .product-header {
      grid-column: 2/3;
    }

    .product-gallery {
      grid-column: 1/2;
      grid-row: 1/3;
    }

    .product-details {
      padding-top: 40px;

      p {
        padding-right: 20%;
      }
    }

    @include mq($desktop-med) {
      grid-template-columns: 2fr 1fr;
    }

    @include mq($desktop-plus) {
      grid-template-columns: 2.5fr 1fr;
    }

  }

  h1 {
    font-family: $pt-sans;
    color: $nepal;
    font-family: $pt-sans;
    font-size: 26px;
    line-height: 1.1;
    letter-spacing: 0.03em;

    span {
      display: block;
      text-transform: uppercase;
    }
  }

  h2 {
    font-family: $pt-sans;
    @include font(20, 30);
    margin-bottom: 8px;
    margin-top: 30px;
  }

  .specs {
    @include no-bullet;
    margin-top: 0;

    li {
      margin-bottom: 8px;
    }
  }

  .radios {
    display: flex;
    padding-bottom: 20px;

    label {
      display: inline-flex;
      align-items: center;
    }

    label:not(:last-child) {
      padding-right: 20px;
    }

    span {
      //white-space: nowrap;
    }

    input[type=radio] {
      position: relative;
      top: -2px;
      margin-right: 8px;
    }
  }

  .product-header {

    @include mq($mobile) {
      margin-top: 30px;
    }

  }

  .product-gallery {
    display: flex;

    @include mq($mobile) {
      margin-top: 40px;
    }

    .thumbs {
      flex: 0 0 auto;
      @include no-bullet;
      max-width: 10%;
      margin: 0;

      li {
        margin: 0 0 15px;
      }

      button {
        height: auto;
      }

      img {
        display: block;
        width: 100%;
        max-width: 100%;
        cursor: pointer;
      }
    }

    figure {
      flex-grow: 1;
      margin-top: 0;
      padding-left: 15px;
    }
  }

  .product-details {

    @include mq($mobile) {
      margin-top: 40px;
    }

  }
}