.homepage {

  header {
    @include mq($mobile) {
      min-height: 165px;
    }
  }

  main section {
    @include mq($mobile) {
      margin-bottom: rem(70);
    }
    @include spacing('margin', 'bottom');
  }

  .photo-main {
    position: relative;
    margin-bottom: rem(60);

    @include mq($desktop) {
      margin-bottom: rem(70);
    }

    .buy-button {
      display: none;
      position: absolute;
      top: 8%;
      right: 16%;

      @include mq($desktop) {
        right: 18%;
      }
    }
  }

  figure {
    position: relative;

    .slideshow {
      display: block;
    }

  }

  figcaption {
    position: absolute;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    color: $nepal;
    text-transform: uppercase;
    font-family: $pt-sans;
    font-size: 18px;
    letter-spacing: 0.025em;
  }

}

