@font-face {
  font-family: 'ptsans';
  src: url("../fonts/ptsans-narrowbold-08.woff2") format("woff2"), url("../fonts/ptsans-narrowbold-08.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'avenir-roman';
  src: url("../fonts/Avenir-Roman.woff2") format("woff2"), url("../fonts/Avenir-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'avenir-medium';
  src: url("../fonts/avenir-medium.woff2") format("woff2"), url("../fonts/avenir-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'pfdin';
  src: url("../fonts/DIN-Regular.woff2") format("woff2"), url("../fonts/DIN-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
 */
html {
  font-size: 100%;
  line-height: 1.75em;
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  box-sizing: border-box; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Add the correct display in IE <10.
 * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
 * Add the correct display in IE for `main`.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * Add the correct display in IE <10.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display and remove excess height in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Add the correct display in IE <11, Safari <8, and Firefox <22.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox <40.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android <4.4.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2rem;
  line-height: 3.5rem;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 1.75rem 0; }

h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h3 {
  font-size: 1.17rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h4 {
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h5 {
  font-size: 0.83rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

h6 {
  font-size: 0.67rem;
  line-height: 1.75rem;
  margin: 1.75rem 0; }

/**
 * Add the correct background and color in IE <10.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE <11.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */
blockquote {
  margin: 1.75rem 40px; }

/**
   * Address margins set differently in IE 6/7.
   */
dl,
menu,
ol,
ul {
  margin: 1.75rem 0; }

/**
     * Turn off margins on nested lists.
     */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0; }

dd {
  margin: 0 0 0 40px; }

/**
   * Address paddings set differently in IE 6/7.
   */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1.75rem 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
   * Set 1 unit of vertical rhythm on the top and bottom margin.
   */
p,
pre {
  margin: 1.75rem 0; }
  p:first-child,
  pre:first-child {
    margin-top: 0; }
  p:last-child,
  pre:last-child {
    margin-bottom: 0; }

/* Forms
   ========================================================================== */
/**
 * Known issues:
 * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
 *   select, unless a border property is set. The default font weight on
 *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
 *   OS X.
 * - It is recommended that you do not style checkbox and radio inputs as
 *   Firefox's implementation does not respect box-sizing, padding, or width.
 * - Certain font size values applied to number inputs cause the cursor style of
 *   the decrement button to change from default to text.
 * - The search input is not fully stylable by default. In Chrome and Safari on
 *   OSX/iOS you can't control font, padding, border, or background. In Chrome
 *   and Safari on Windows you can't control border properly. It will apply
 *   border-width but will only show a border color (which cannot be controlled)
 *   for the outer 1px of that border. Applying -webkit-appearance: textfield
 *   addresses these issues without removing the benefits of search inputs (e.g.
 *   showing past searches). Safari (but not Chrome) will clip the cancel button
 *   on when it has padding (and textfield appearance).
 */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 4. Improve appearance and consistency with IE 6/7.
 */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible; }

/**
 * 1. Add the correct box sizing in IE <11.
 * 2. Remove the padding in IE <11.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
   */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 * 4. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */ }

/**
 * Restore the font weight unset by a previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
* Animation Mixins
*/
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes search-open {
  from {
    opacity: 0;
    height: 0; }
  to {
    opacity: 1;
    height: 104px; } }

@keyframes sub-menu-down {
  from {
    opacity: 0;
    top: 0; }
  to {
    opacity: 1;
    top: 60px; } }

.clearfix::after {
  display: block;
  clear: both;
  content: "" !important; }

/**
 * Returns a percentage width based on the number and total number of columns
 *
 * @param   int     $num    Number of columns (e.g. 4)
 * @param   int     $total  Total number of columns (e.g. 24)
 * @return  float            Total width (%)
 */
/*  Unitless line-height
*
*   @param {Font Size} $fontSize - Unitless font size
*   @param {Leading}   $leading  - Unitless leading from photoshop or sketch
*
*   Example Usage:
*
*     @include line-height(16, 20);
*
*/
/*  Remove List Styles
*
*   No Params
*
*   Example Usage:
*
*     ul {
*       @include no-bullet;
*     }
*
*/
/*  Media Query Mixin
*
*   @param {Media Query} $mq - Media query in single quotes: eg. 'screen and (min-width: 480px)'
*
*   Example Usage:
*
*     .selector {
*       property: value;
*
*       @include mq($bp768) {
*         property: value;
*       }
*     }
*
*/
/*  Placeholder Mixin
*
*   No Params
*
*   Example Usage:
*
*     input[placeholder] {
*       @include placeholder {
*         color: red;
*       }
*     }
*
*/
/*  PX to REM Conversion
*
*   @param {Pixel} $px - Unitless pixel value to be converted into rems.
*
*   Example Usage:
*
*     margin-bottom: rem(20);
*
*/
/**
 * Hide an element, whilst making its content available to screen readers
 * and meeting WCAG AA guidelines
 */
.empress {
  color: #767676; }

/**
* Fonts
*/
.visually-hidden,
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden; }

a[href],
button,
input,
select,
textarea,
object {
  outline-color: #1879CD; }
  .keyboard-user a[href]:focus, .keyboard-user
  button:focus, .keyboard-user
  input:focus, .keyboard-user
  select:focus, .keyboard-user
  textarea:focus, .keyboard-user
  object:focus {
    box-shadow: 0 0 0 0.375rem #1879cd;
    border-radius: 8px; }
  .keyboard-user.pointerevents a[href]:focus:hover, .keyboard-user.pointerevents
  button:focus:hover, .keyboard-user.pointerevents
  input:focus:hover, .keyboard-user.pointerevents
  select:focus:hover, .keyboard-user.pointerevents
  textarea:focus:hover, .keyboard-user.pointerevents
  object:focus:hover {
    box-shadow: none; }

.skip-link {
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 15px;
  z-index: 9999;
  background: #F40606;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold; }
  .skip-link:focus, .skip-link:active, .skip-link:hover {
    transition: 150ms ease-in-out;
    text-decoration: none; }
  .skip-link:not(:focus) {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden; }

* {
  -webkit-overflow-scrolling: touch; }

html,
body {
  overflow-x: hidden;
  height: 100%; }
  html.slideshow-open .layout-container,
  body.slideshow-open .layout-container {
    position: fixed; }

main {
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  min-height: 500px; }
  main::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    main {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    main {
      padding-right: 100px;
      padding-left: 100px; } }
  @media screen and (max-width: 1023px) {
    main {
      position: relative;
      z-index: 2;
      min-height: 300px; } }
  @media screen and (min-width: 1024px) {
    body.fixed-nav main {
      padding-top: 60px; } }

figure img,
.slide img,
.image img {
  display: block;
  width: 100%;
  height: auto; }

.thumb-list .views-row {
  margin-bottom: 1.875rem; }
  @media screen and (min-width: 620px) {
    .thumb-list .views-row {
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 1350px) {
    .thumb-list .views-row {
      margin-bottom: 3.5rem; } }

/* ==========================================================================
 Print styles.
 https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
 ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .breadcrumb a[href]:after {
    content: none; } }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-size: 100%;
  line-height: 1.5; }

.menu {
  list-style: none;
  padding: 0; }
  .menu li {
    padding: 0;
    margin: 0; }

.thumb-list ul,
.item-list ul {
  list-style: none;
  padding: 0; }

nav ul,
nav ol {
  list-style: none;
  padding: 0; }
  nav ul li,
  nav ol li {
    padding: 0;
    margin: 0; }

dl {
  margin-top: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

address {
  font-style: normal; }

form {
  margin: 0; }

fieldset {
  padding: 0;
  margin: 0;
  border: 0; }

textarea {
  resize: vertical; }

button {
  background-color: transparent;
  border: 0;
  padding: 0; }

[type="search"]::-ms-clear {
  display: none; }

iframe {
  border: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td {
  padding: 0; }

th {
  font-weight: normal; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body {
  color: #212121;
  font-family: "avenir-roman", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%; }

a {
  cursor: pointer;
  color: #88A9BE;
  text-decoration: none; }
  a:focus, a:active, a:hover {
    transition: 150ms ease-in-out;
    text-decoration: underline; }

h1 {
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.1539;
  margin-top: 0; }
  @media screen and (min-width: 620px) {
    h1 {
      font-size: 1.875rem;
      line-height: 1.1334; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 2.5rem;
      line-height: 1.1; } }
  @media screen and (min-width: 1212px) {
    h1 {
      font-size: 2.75rem;
      line-height: 1.091; } }
  @media screen and (min-width: 1350px) {
    h1 {
      font-size: 2.875rem;
      line-height: 1.087; } }

h2 {
  margin-top: 1.875rem;
  font-weight: normal;
  text-transform: none;
  font-size: 1.5rem;
  line-height: 1.1667; }
  @media screen and (min-width: 620px) {
    h2 {
      margin-top: 2.25rem;
      font-size: 1.75rem;
      line-height: 1.1429; } }
  @media screen and (min-width: 1024px) {
    h2 {
      margin-top: 2.625rem;
      font-size: 2rem;
      line-height: 1.125; } }
  @media screen and (min-width: 1350px) {
    h2 {
      margin-top: 3.125rem;
      font-size: 2.25rem;
      line-height: 1.1112; } }

h3 {
  font-weight: bold; }

h3,
p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

p a {
  text-decoration: underline; }

strong {
  font-weight: bold; }

blockquote {
  position: relative;
  padding: 0 8.33333% 0 8.33333%;
  margin: 1.25rem 0;
  font-size: 1.125rem;
  line-height: 1.3334;
  color: #767676;
  font-style: italic; }
  @media screen and (min-width: 620px) {
    blockquote {
      font-size: 1.875rem;
      line-height: 1.2;
      margin: 1.25rem 0 2.25rem; } }
  @media screen and (min-width: 1024px) {
    blockquote {
      font-size: 2.25rem;
      line-height: 1.1945;
      margin: 2.5rem 0 2.875rem; } }
  blockquote p {
    font-size: 1.125rem;
    line-height: 1.3334; }
    @media screen and (min-width: 620px) {
      blockquote p {
        font-size: 1.875rem;
        line-height: 1.2; } }

figcaption {
  font-size: 0.9375rem;
  line-height: 1.4667; }
  @media screen and (min-width: 620px) {
    figcaption {
      font-size: 1rem;
      line-height: 1.25; } }
  @media screen and (min-width: 1350px) {
    figcaption {
      font-size: 1.125rem;
      line-height: 1.3334; } }

.addthis-smartlayers #at4-share,
.addthis-smartlayers #at-share-dock {
  display: none !important; }
  .addthis-smartlayers #at4-share body.mobile-nav-open,
  .addthis-smartlayers #at-share-dock body.mobile-nav-open {
    z-index: -1 !important; }
  @media screen and (min-width: 620px) and (max-width: 1023px) {
    body.search-bar-open .addthis-smartlayers #at4-share, body.search-bar-open
    .addthis-smartlayers #at-share-dock {
      z-index: 10 !important; } }
  @media screen and (min-width: 620px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 201px !important; } }
  @media screen and (min-width: 1024px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 296px !important; } }
  @media screen and (min-width: 1212px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 309px !important; } }
  @media screen and (min-width: 1350px) {
    .addthis-smartlayers #at4-share,
    .addthis-smartlayers #at-share-dock {
      top: 317px !important; } }
  .addthis-smartlayers #at4-share > a,
  .addthis-smartlayers #at-share-dock > a {
    border: solid 1px #ccc; }
    @media screen and (min-width: 620px) {
      .addthis-smartlayers #at4-share > a:nth-child(2),
      .addthis-smartlayers #at-share-dock > a:nth-child(2) {
        top: -1px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(3),
      .addthis-smartlayers #at-share-dock > a:nth-child(3) {
        top: -2px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(4),
      .addthis-smartlayers #at-share-dock > a:nth-child(4) {
        top: -3px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(5),
      .addthis-smartlayers #at-share-dock > a:nth-child(5) {
        top: -4px !important; }
      .addthis-smartlayers #at4-share > a:nth-child(6),
      .addthis-smartlayers #at-share-dock > a:nth-child(6) {
        top: -5px !important; } }

.node-type-event #at4-share,
.node-type-event #at-share-dock,
.node-type-article #at4-share,
.node-type-article #at-share-dock,
.node-type-news-article #at4-share,
.node-type-news-article #at-share-dock,
.node-type-resource #at4-share,
.node-type-resource #at-share-dock,
.node-type-ta-program #at4-share,
.node-type-ta-program #at-share-dock,
.node-type-curriculum #at4-share,
.node-type-curriculum #at-share-dock {
  display: flex !important; }
  @media screen and (min-width: 620px) {
    .node-type-event #at4-share,
    .node-type-event #at-share-dock,
    .node-type-article #at4-share,
    .node-type-article #at-share-dock,
    .node-type-news-article #at4-share,
    .node-type-news-article #at-share-dock,
    .node-type-resource #at4-share,
    .node-type-resource #at-share-dock,
    .node-type-ta-program #at4-share,
    .node-type-ta-program #at-share-dock,
    .node-type-curriculum #at4-share,
    .node-type-curriculum #at-share-dock {
      display: block !important; } }
  @media screen and (min-width: 620px) and (max-width: 1023px) {
    .no-pointerevents .node-type-event #at4-share, .no-pointerevents
    .node-type-event #at-share-dock, .no-pointerevents
    .node-type-article #at4-share, .no-pointerevents
    .node-type-article #at-share-dock, .no-pointerevents
    .node-type-news-article #at4-share, .no-pointerevents
    .node-type-news-article #at-share-dock, .no-pointerevents
    .node-type-resource #at4-share, .no-pointerevents
    .node-type-resource #at-share-dock, .no-pointerevents
    .node-type-ta-program #at4-share, .no-pointerevents
    .node-type-ta-program #at-share-dock, .no-pointerevents
    .node-type-curriculum #at4-share, .no-pointerevents
    .node-type-curriculum #at-share-dock {
      display: none !important; } }

.bar {
  position: absolute;
  z-index: 1;
  transition: 150ms ease-in-out;
  display: block;
  background: #FFFFFF;
  width: 220px;
  height: 6px;
  left: 15px;
  bottom: 200px; }
  @media screen and (min-width: 620px) {
    .bar {
      width: 300px;
      left: 30px; } }
  @media screen and (min-width: 1024px) {
    .bar {
      left: 100px; } }
  @media screen and (min-width: 1212px) {
    .bar {
      width: 400px; } }
  @media screen and (min-width: 1350px) {
    .bar {
      width: 480px; } }

.button {
  display: inline-block;
  height: auto;
  padding: 0.9375rem 1.5625rem;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background: #c67c55;
  color: #FFFFFF;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1;
  background-clip: padding-box;
  border: 0; }
  .button:focus, .button:active, .button:hover {
    transition: 150ms ease-in-out;
    text-decoration: none;
    background-color: #cc8b68; }
  .button span {
    text-transform: lowercase; }

.buy-button {
  width: 60px;
  height: 60px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FFFFFF;
  color: #82abc6;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  transition: 150ms ease-in-out; }
  @media screen and (min-width: 620px) {
    .buy-button {
      width: 70px;
      height: 70px;
      font-size: 20px; } }
  @media screen and (min-width: 1024px) {
    .buy-button {
      width: 80px;
      height: 80px;
      font-size: 22px; } }
  .buy-button:hover {
    background: #82abc6;
    color: #FFFFFF;
    text-decoration: none; }

.hamburger-menu {
  position: static;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;
  background-color: transparent;
  line-height: inherit;
  text-align: inherit;
  color: #000000;
  outline: 0;
  position: absolute;
  z-index: 999;
  top: 3px;
  right: 0;
  width: 55px;
  height: 70px;
  /**
   * Close
   */ }
  @media screen and (min-width: 620px) {
    .hamburger-menu {
      display: none;
      width: 92px;
      height: 90px; } }
  @media screen and (min-width: 1024px) {
    .hamburger-menu {
      width: 112px;
      top: 40px; } }
  .hamburger-menu .icon {
    display: block;
    width: 25px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    transition: 150ms ease-in-out; }
    @media screen and (min-width: 620px) {
      .hamburger-menu .icon {
        width: 32px;
        height: 20px; } }
    .hamburger-menu .icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #000000;
      opacity: 1;
      left: 15px;
      transform: rotate(0deg);
      transition: 150ms ease-in-out;
      animation: fade-in 150ms ease-in-out; }
      @media screen and (min-width: 620px) {
        .hamburger-menu .icon span {
          left: 30px;
          height: 3px; } }
      .hamburger-menu .icon span:nth-child(1) {
        top: 0px; }
      .hamburger-menu .icon span:nth-child(2), .hamburger-menu .icon span:nth-child(3) {
        top: 6px;
        background: transparent; }
        @media screen and (min-width: 620px) {
          .hamburger-menu .icon span:nth-child(2), .hamburger-menu .icon span:nth-child(3) {
            top: 8px; } }
      .hamburger-menu .icon span:nth-child(4) {
        top: 10px; }
        @media screen and (min-width: 620px) {
          .hamburger-menu .icon span:nth-child(4) {
            top: 14px; } }
  .hamburger-menu.active .icon span {
    font-size: 0 !important;
    line-height: 0 !important;
    text-shadow: unset !important; }
    .hamburger-menu.active .icon span:nth-child(1) {
      display: none; }
    .hamburger-menu.active .icon span:nth-child(2) {
      transform: rotate(45deg);
      background: #000000; }
    .hamburger-menu.active .icon span:nth-child(3) {
      transform: rotate(-45deg);
      background: #000000; }
    .hamburger-menu.active .icon span:nth-child(4) {
      display: none; }

.mc-field-group input[type=text],
.mc-field-group input[type=email],
.mc-field-group input[type=phone],
.mc-field-group input[type=password],
.mc-field-group textarea,
.mc-field-group select {
  width: 100%; }

.mc-field-group.input-group > strong {
  display: block;
  margin-bottom: 1.25rem; }

.mc-field-group.input-group > ul {
  padding: 0;
  margin: 0; }

.indicates-required {
  color: #767676;
  font-style: italic;
  text-align: right;
  font-size: 0.8125rem; }
  @media screen and (min-width: 620px) {
    .indicates-required {
      font-size: 0.9375rem; } }

#mc-embedded-subscribe {
  margin-top: 1.25rem; }

figure figcaption {
  margin: 0.75rem 0; }

@media screen and (min-width: 620px) {
  figure {
    margin: 1.5rem 0; } }

@media screen and (min-width: 1350px) {
  figure {
    margin: 2.125rem 0; } }

figure img {
  max-width: 100%; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .video-container.tv {
    padding-bottom: 75%; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.social-media-icons ul {
  display: flex;
  list-style: none;
  padding: 0; }
  .social-media-icons ul > li {
    margin: 0 0 0 1.25rem; }
    .social-media-icons ul > li:first-child {
      margin-left: 0; }
    .social-media-icons ul > li > a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.625rem 0 0;
      width: 40px;
      height: 40px; }
      .social-media-icons ul > li > a .name {
        position: absolute !important;
        clip: rect(1px 1px 1px 1px);
        /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden; }
      .social-media-icons ul > li > a:focus, .social-media-icons ul > li > a:active, .social-media-icons ul > li > a:hover {
        transition: 150ms ease-in-out;
        color: #1879CD;
        text-decoration: none; }
      .social-media-icons ul > li > a.fb {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.fb::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-fb-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 150ms ease-in-out, height 150ms ease-in-out, opacity 150ms ease-in-out, transform 150ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.fb::after {
          width: 40px;
          height: 40px; }
      .social-media-icons ul > li > a.ig {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.ig::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-ig-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 150ms ease-in-out, height 150ms ease-in-out, opacity 150ms ease-in-out, transform 150ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.ig::after {
          width: 40px;
          height: 40px; }
      .social-media-icons ul > li > a.yt {
        position: relative;
        width: 40px;
        height: 40px; }
        .social-media-icons ul > li > a.yt::before {
          width: 40px;
          height: 40px;
          content: '';
          display: block;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          background-image: url("../images/social-yt-white.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 0 !important;
          line-height: 0 !important;
          text-shadow: unset !important;
          transform-origin: center;
          transition: width 150ms ease-in-out, height 150ms ease-in-out, opacity 150ms ease-in-out, transform 150ms ease-in-out;
          transform: translate(-50%, -50%); }
        .social-media-icons ul > li > a.yt::after {
          width: 40px;
          height: 40px; }
    .social-media-icons ul > li:last-child a {
      padding-right: 0; }

footer {
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 0.875rem; }
  footer::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    footer {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    footer {
      padding-right: 100px;
      padding-left: 100px; } }

header {
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 0;
  position: relative;
  min-height: 100px; }
  header::after {
    display: block;
    clear: both;
    content: "" !important; }
  @media screen and (min-width: 620px) {
    header {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1024px) {
    header {
      padding-right: 100px;
      padding-left: 100px; } }
  @media screen and (min-width: 620px) {
    header {
      padding-top: 60px;
      padding-bottom: 0;
      min-height: 230px; } }
  @media screen and (min-width: 1024px) {
    header {
      padding-top: 80px;
      padding-bottom: 0;
      min-height: 260px; } }
  header .top-row {
    display: flex; }
  header .logo {
    display: inline-block;
    margin-right: auto;
    color: #C09272;
    font-family: "pfdin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 32px;
    line-height: 1.1;
    text-transform: uppercase;
    letter-spacing: -0.025em; }
    header .logo:hover {
      text-decoration: none; }
    @media screen and (min-width: 1024px) {
      header .logo {
        margin-left: 100px; } }
    header .logo h1 {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0; }
  header .description {
    margin-bottom: 0;
    margin-top: 10px; }
    @media screen and (min-width: 1024px) {
      header .description {
        padding-left: 100px; } }
    header .description p {
      font-size: 0.875rem;
      line-height: 1.4286; }
      @media screen and (max-width: 619px) {
        header .description p {
          font-size: 0.8125rem;
          line-height: 1.3847; } }

nav {
  transition: 150ms ease-in-out; }
  @media screen and (max-width: 619px) {
    nav {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      animation: fade-in 150ms ease-in-out;
      background: rgba(255, 255, 255, 0.9); } }
  body.nav-open nav {
    opacity: 1; }
    @media screen and (max-width: 619px) {
      body.nav-open nav {
        display: block; } }
  nav ul {
    display: flex; }
    @media screen and (max-width: 619px) {
      nav ul {
        display: block;
        margin-top: 80px;
        text-align: center; } }
    @media screen and (min-width: 620px) {
      nav ul {
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      nav ul li {
        margin-left: 20px; }
        nav ul li:last-child a {
          padding-right: 0; } }
    @media screen and (max-width: 619px) {
      nav ul li {
        margin: 10px 0; } }
    nav ul li a {
      display: block;
      padding: 20px;
      color: #000000;
      font-size: 14px;
      line-height: 1; }
      @media screen and (max-width: 619px) {
        nav ul li a {
          font-size: 1.5rem;
          line-height: 1;
          padding: 20px 0; }
          nav ul li a:hover {
            text-decoration: none; } }
      @media screen and (min-width: 620px) {
        nav ul li a.active {
          color: #88A9BE;
          text-decoration: none;
          cursor: default; } }

.slideshow-grid {
  margin: 0 15px 30px; }
  @media screen and (min-width: 620px) {
    .slideshow-grid {
      margin: 0 30px 30px; } }
  @media screen and (min-width: 1024px) {
    .slideshow-grid {
      margin: 0 100px 50px; } }
  .slideshow-grid ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 15px; }
    @media screen and (min-width: 620px) {
      .slideshow-grid ul {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-gap: 30px; } }
    @media screen and (min-width: 1024px) {
      .slideshow-grid ul {
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
        grid-gap: 20px; } }
    .slideshow-grid ul li {
      display: block;
      position: relative; }
      .slideshow-grid ul li::after {
        content: '';
        display: block;
        padding-bottom: 56.25%; }
      .slideshow-grid ul li > button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
  .slideshow-grid button {
    position: static;
    width: auto;
    height: auto;
    border: 0;
    padding: 0;
    background-color: transparent;
    line-height: inherit;
    text-align: inherit;
    color: #000000;
    outline: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%; }

.slideshow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%; }
  @media screen and (max-width: 619px) {
    .slideshow {
      width: 100vw;
      overflow-x: hidden; } }
  body.home .slideshow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    body.home .slideshow .slide,
    body.home .slideshow .slick-track,
    body.home .slideshow .slick-list {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%; }
  .slideshow .slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    body.home .slideshow .slide::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3); }
  .slideshow .caption {
    display: none !important;
    color: #FFFFFF;
    font-size: 1.25rem;
    position: absolute;
    z-index: 12;
    transition: 150ms ease-in-out;
    width: 220px;
    left: 15px;
    bottom: 6%;
    padding-top: 0.625rem;
    border-top: solid 4px #FFFFFF; }
    @media screen and (max-width: 619px) {
      .slideshow .caption {
        width: 275px;
        left: 15px;
        bottom: -40px;
        border-top: 0;
        font-size: 1rem; }
        body.home .slideshow .caption {
          bottom: 6%;
          border-top: solid 3px #FFFFFF; } }
    @media screen and (min-width: 620px) {
      .slideshow .caption {
        width: 300px;
        left: 30px;
        bottom: 24px; } }
    @media screen and (min-width: 1024px) {
      .slideshow .caption {
        left: 100px;
        display: none; }
        body.home .slideshow .caption {
          display: block; } }
    @media screen and (min-width: 1212px) {
      .slideshow .caption {
        width: 400px; } }
    @media screen and (min-width: 1350px) {
      .slideshow .caption {
        width: 480px; } }

.slick-list {
  overflow: visible; }

.slideshow-container {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: 150ms ease-in-out;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8); }
  body.slideshow-open .slideshow-container {
    visibility: visible;
    opacity: 1; }
  .slideshow-container > .slideshow:focus, .slideshow-container > .slideshow:active, .slideshow-container > .slideshow:hover {
    transition: 150ms ease-in-out; }
    .slideshow-container > .slideshow:focus .slick-arrow,
    .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-arrow,
    .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-arrow,
    .slideshow-container > .slideshow:hover .slick-dots {
      display: block !important;
      animation: fade-in 150ms ease-in-out; }
    .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-dots {
      display: none !important; }
      @media screen and (max-width: 619px) {
        .slideshow-container > .slideshow:focus .slick-dots, .slideshow-container > .slideshow:active .slick-dots, .slideshow-container > .slideshow:hover .slick-dots {
          display: none !important; } }
    .slideshow-container > .slideshow:focus .caption, .slideshow-container > .slideshow:active .caption, .slideshow-container > .slideshow:hover .caption {
      display: block;
      animation: fade-in 150ms ease-in-out; }
  @media screen and (max-width: 1023px) {
    .slideshow-container .slick-track {
      position: absolute;
      margin-top: 90px; } }
  @media screen and (max-width: 619px) {
    .slideshow-container .slick-track {
      margin-top: 70px; } }
  @media screen and (min-width: 620px) {
    .slideshow-container .slick-slide > div {
      display: flex;
      height: 100vh;
      justify-content: center; } }
  .slideshow-container .slick-slide .slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw !important;
    max-width: 142.22222222222223vh;
    height: 80vh;
    max-height: 45vw; }
    @media screen and (max-width: 619px) {
      .slideshow-container .slick-slide .slide {
        width: 100vw !important;
        max-width: 177.77777777777777vh;
        height: 100vh;
        max-height: 56.25vw; } }
    @media screen and (min-width: 620px) {
      .slideshow-container .slick-slide .slide {
        height: auto;
        max-height: auto !important; } }
    @media screen and (min-width: 1024px) {
      .slideshow-container .slick-slide .slide {
        margin-top: 160px; } }

.slick-dots {
  display: none !important;
  list-style: none;
  padding: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  bottom: -30px;
  color: #FFFFFF; }
  .slick-dots li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background: rgba(0, 0, 0, 0.5);
    border: 0; }
    .slick-dots li.slick-active {
      background-color: rgba(255, 255, 255, 0.9); }
  .slick-dots button {
    color: #FFFFFF;
    font-size: 0 !important;
    line-height: 0 !important;
    text-shadow: unset !important; }

.slick-arrow {
  display: none !important;
  position: absolute;
  z-index: 100;
  bottom: 30px;
  font-size: 0 !important;
  line-height: 0 !important;
  text-shadow: unset !important;
  outline: 0; }
  @media screen and (max-width: 1023px) {
    .slick-arrow {
      visibility: hidden; } }

.slick-prev {
  left: 35px; }
  @media screen and (min-width: 1024px) {
    .slick-prev {
      left: 55px; } }
  @media screen and (min-width: 1024px) {
    .slick-prev {
      margin-top: 160px; } }
  .slick-prev::after {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border-width: 2px 0 0 2px;
    border-color: #FFFFFF;
    border-style: solid;
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg); }

.slick-next {
  right: 35px; }
  @media screen and (min-width: 1024px) {
    .slick-next {
      right: 55px; } }
  .slick-next::after {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border-width: 2px 0 0 2px;
    border-color: #FFFFFF;
    border-style: solid;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }

.about h1, .about h2 {
  color: #88A9BE;
  text-transform: uppercase;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0.025em; }

.about h3 {
  font-style: italic;
  margin-bottom: 0; }

.about h4 {
  margin-bottom: 0; }

.about p {
  margin-top: 0; }

@media screen and (max-width: 619px) {
  .about section {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; } }
  @media screen and (max-width: 619px) and (min-width: 620px) {
    .about section {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem; } }
  @media screen and (max-width: 619px) and (min-width: 1350px) {
    .about section {
      margin-top: 5rem;
      margin-bottom: 5rem; } }

@media screen and (min-width: 1024px) {
  .about section {
    margin-bottom: 3.75rem;
    padding-left: 6.25rem;
    padding-right: 20%; } }
  @media screen and (min-width: 1024px) and (min-width: 620px) {
    .about section {
      margin-bottom: 4.375rem; } }
  @media screen and (min-width: 1024px) and (min-width: 1024px) {
    .about section {
      margin-bottom: 5rem; } }

.artwork section {
  display: grid; }
  @media screen and (max-width: 619px) {
    .artwork section {
      margin-top: 3.125rem;
      margin-bottom: 3.125rem;
      grid-gap: 36px; } }
  @media screen and (max-width: 619px) and (min-width: 620px) {
    .artwork section {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem; } }
  @media screen and (max-width: 619px) and (min-width: 1350px) {
    .artwork section {
      margin-top: 5rem;
      margin-bottom: 5rem; } }
  @media screen and (max-width: 619px) {
      .artwork section .pane-1 {
        padding-right: 20%; } }
  @media screen and (min-width: 620px) {
    .artwork section {
      grid-template-columns: .8fr 1fr;
      grid-gap: 8%; } }
  @media screen and (min-width: 1024px) {
    .artwork section {
      margin-bottom: 3.75rem;
      padding-left: 6.25rem;
      padding-right: 9%; } }
  @media screen and (min-width: 1024px) and (min-width: 620px) {
    .artwork section {
      margin-bottom: 4.375rem; } }
  @media screen and (min-width: 1024px) and (min-width: 1024px) {
    .artwork section {
      margin-bottom: 5rem; } }
  .artwork section img {
    max-width: 100%; }

@media screen and (max-width: 619px) {
  .homepage header {
    min-height: 165px; } }

.homepage main section {
  margin-bottom: 3.75rem; }
  @media screen and (max-width: 619px) {
    .homepage main section {
      margin-bottom: 4.375rem; } }
  @media screen and (min-width: 620px) {
    .homepage main section {
      margin-bottom: 4.375rem; } }
  @media screen and (min-width: 1024px) {
    .homepage main section {
      margin-bottom: 5rem; } }

.homepage .photo-main {
  position: relative;
  margin-bottom: 3.75rem; }
  @media screen and (min-width: 1024px) {
    .homepage .photo-main {
      margin-bottom: 4.375rem; } }
  .homepage .photo-main .buy-button {
    display: none;
    position: absolute;
    top: 8%;
    right: 16%; }
    @media screen and (min-width: 1024px) {
      .homepage .photo-main .buy-button {
        right: 18%; } }

.homepage figure {
  position: relative; }
  .homepage figure .slideshow {
    display: block; }

.homepage figcaption {
  position: absolute;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  color: #88A9BE;
  text-transform: uppercase;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0.025em; }

@media screen and (max-width: 619px) {
  .interview header {
    min-height: 175px; } }

.interview h1 {
  text-align: center;
  color: #88A9BE;
  text-transform: uppercase;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 20px;
  letter-spacing: 0.025em; }

.interview h3 {
  text-align: center;
  color: #88A9BE;
  text-transform: uppercase;
  font-family: "avenir-medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  letter-spacing: 0.025em;
  font-weight: bold;
  margin-top: 26px;
  margin-bottom: 10px; }

.interview figure {
  margin-bottom: 20px; }

.interview article p {
  text-align: center;
  font-family: times;
  font-size: 0.9375rem;
  line-height: 1.3334;
  margin-top: 10px; }
  @media screen and (min-width: 620px) {
    .interview article p {
      padding-left: 10%;
      padding-right: 10%; } }
  @media screen and (min-width: 1024px) {
    .interview article p {
      padding-left: 22%;
      padding-right: 22%; } }

@media screen and (min-width: 620px) {
  .product-layout {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 1.6fr 1fr;
    grid-template-rows: auto 1fr; }
    .product-layout .product-header {
      grid-column: 2/3; }
    .product-layout .product-gallery {
      grid-column: 1/2;
      grid-row: 1/3; }
    .product-layout .product-details {
      padding-top: 40px; }
      .product-layout .product-details p {
        padding-right: 20%; } }
  @media screen and (min-width: 620px) and (min-width: 1212px) {
    .product-layout {
      grid-template-columns: 2fr 1fr; } }
  @media screen and (min-width: 620px) and (min-width: 1350px) {
    .product-layout {
      grid-template-columns: 2.5fr 1fr; } }

.product-layout h1 {
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #88A9BE;
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 26px;
  line-height: 1.1;
  letter-spacing: 0.03em; }
  .product-layout h1 span {
    display: block;
    text-transform: uppercase; }

.product-layout h2 {
  font-family: "ptsans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 8px;
  margin-top: 30px; }

.product-layout .specs {
  list-style: none;
  padding: 0;
  margin-top: 0; }
  .product-layout .specs li {
    margin-bottom: 8px; }

.product-layout .radios {
  display: flex;
  padding-bottom: 20px; }
  .product-layout .radios label {
    display: inline-flex;
    align-items: center; }
  .product-layout .radios label:not(:last-child) {
    padding-right: 20px; }
  .product-layout .radios input[type=radio] {
    position: relative;
    top: -2px;
    margin-right: 8px; }

@media screen and (max-width: 619px) {
  .product-layout .product-header {
    margin-top: 30px; } }

.product-layout .product-gallery {
  display: flex; }
  @media screen and (max-width: 619px) {
    .product-layout .product-gallery {
      margin-top: 40px; } }
  .product-layout .product-gallery .thumbs {
    flex: 0 0 auto;
    list-style: none;
    padding: 0;
    max-width: 10%;
    margin: 0; }
    .product-layout .product-gallery .thumbs li {
      margin: 0 0 15px; }
    .product-layout .product-gallery .thumbs button {
      height: auto; }
    .product-layout .product-gallery .thumbs img {
      display: block;
      width: 100%;
      max-width: 100%;
      cursor: pointer; }
  .product-layout .product-gallery figure {
    flex-grow: 1;
    margin-top: 0;
    padding-left: 15px; }

@media screen and (max-width: 619px) {
  .product-layout .product-details {
    margin-top: 40px; } }
