/*  Placeholder Mixin
*
*   No Params
*
*   Example Usage:
*
*     input[placeholder] {
*       @include placeholder {
*         color: red;
*       }
*     }
*
*/
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}
