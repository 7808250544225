// GLOBAL LAYOUT ELEMENTS

* {
  -webkit-overflow-scrolling: touch;
}

html,
body {
  overflow-x: hidden;
  height: 100%;

  &.slideshow-open {
    //overflow-y: hidden;

    .layout-container {
      position: fixed;
    }
  }
}

main {
  @include layout-container;
  min-height: 500px;

  @include mq($tablet-max) {
    position: relative;
    z-index: 2;
    min-height: 300px;
  }

  // desktop sticky nav
  body.fixed-nav & {

    @include mq($desktop) {
      padding-top: 60px;
    }

  }//.mobile-nav-open &

}//main#main

figure,
.slide,
.image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.thumb-list {

  .views-row {
    margin-bottom: rem(30);

    @include mq($tablet) {
      margin-bottom: rem(40);
    }

    @include mq($desktop-plus) {
      margin-bottom: rem(56);
    }

  }

}//.thumb-list
