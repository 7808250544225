nav {
  @include transition;

  @include mq($mobile) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    @include fade-in;
    background: $white-transparent;

  }

  body.nav-open & {
    opacity: 1;

    @include mq($mobile) {
      display: block;
    }

  }

  ul {
    display: flex;

    @include mq($mobile) {
      display: block;
      margin-top: 80px;
      text-align: center;
    }

    @include mq($tablet) {
      margin-top: 0;
    }

    li {

      @include mq($desktop) {
        margin-left: 20px;

        &:last-child {
          a { padding-right: 0; }
        }

      }

      @include mq($mobile) {
        margin: 10px 0;
      }

      a {
        display: block;
        padding: 20px;
        color: $black;
        font-size: 14px;
        line-height: 1;

        @include mq($mobile) {
          @include font(24, 24);
          padding: 20px 0;

          &:hover {
            text-decoration: none;
          }

        }

        @include mq($tablet) {
          &.active {
            color: $nepal;
            text-decoration: none;
            cursor: default;
          }
        }

      }

    }

  }

}
