// base general typography

body {
  color: $nero;
  font-family: $avenir-roman;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

a {
  cursor: pointer;
  color: $nepal;
  text-decoration: none;

  @include hover {
    text-decoration: underline;
  }

}

h1 {
  font-weight: normal;
  @include font(26, 30);
  margin-top: 0;

  @include mq($tablet) {
    @include font(30, 34);
  }

  @include mq($desktop) {
    @include font(40, 44);
  }

  @include mq($desktop-med) {
    @include font(44, 48);
  }

  @include mq($desktop-plus) {
    @include font(46, 50);
  }

}//h1

h2 {
  margin-top: rem(30);
  font-weight: normal;
  text-transform: none;
  @include font(24, 28);

  @include mq($tablet) {
    margin-top: rem(36);
    @include font(28, 32);
  }

  @include mq($desktop) {
    margin-top: rem(42);
    @include font(32, 36);
  }

  @include mq($desktop-plus) {
    margin-top: rem(50);
    @include font(36, 40);
  }

}//h2

h3 {
  font-weight: bold;

}//h4

h3,
p,
%p {
  @include margin(rem(24) null);
  @include font(16, 24);
}

p {
  a { text-decoration: underline; }
}

strong {
  font-weight: bold;
}//strong

blockquote {
  position: relative;
  padding: 0 cols(2) 0 cols(2);
  margin: rem(20) 0;
  @include font(18, 24);
  color: $empress;
  font-style: italic;

  @include mq($tablet) {
    @include font(30, 36);
    margin: rem(20) 0 rem(36);
  }

  @include mq($desktop) {
    @include font(36, 43);
    margin: rem(40) 0 rem(46);
  }

  p {
    @include font(18, 24);

    @include mq($tablet) {
      @include font(30, 36);
    }

  }//p

}//blockquote


figcaption {
  @include font(15, 22);

  @include mq($tablet) {
    @include font(16, 20);
  }

  @include mq($desktop-plus) {
    @include font(18, 24);
  }

}//figcaption
