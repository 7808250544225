// http://www.color-blindness.com/color-name-hue/

// basics
$white: #FFFFFF;
$black: #000000;

// grays
$alto: #dddddd;               // footer logo
$dim-grey: #666666;           // grey text
$empress: #767676;            // lightest gray for contrast
$gainsboro: #e5e5e5;          // footer icons
$nero: #212121;               // body text
$night-rider: #313131;        // non-text blocky black
$silver: #bbb;                // borders for tables with background
$very-light-grey: #ccc;       // borders
$white-smoke: #efefef;        // footer text
$white-smoke2: #f5f5f5;       // backgrounds

// blues
$denim: #1879CD;              // links
$polo-blue: #82abc6;          // buttons
$nepal: #88A9BE;

// reds, yellow, orange, browns
$cameo: #C09272;              // earthart logo
$red: #F40606;                // accents
$blanched-almond: #FFE8CC;    //callouts
$japonica: #c67c55;           // buttons

// black opacity
$black-light-transparent: rgba($black, 0.30);
$black-transparent: rgba($black, 0.50);
$black-dark-transparent: rgba($black, 0.80);

// white opacity
$white-transparent: rgba($white, 0.9);

// classes that use above vars

.empress {color: $empress;}
