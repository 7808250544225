/*  Unitless line-height
*
*   @param {Font Size} $fontSize - Unitless font size
*   @param {Leading}   $leading  - Unitless leading from photoshop or sketch
*
*   Example Usage:
*
*     @include line-height(16, 20);
*
*/
@mixin line-height($fontSize, $leading) {
  line-height: ceil(($leading / $fontSize) * 10000) / 10000;
}
