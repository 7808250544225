.bar {
  position: absolute;
  z-index: 1;
  @include transition;
  display: block;
  background: $white;
  width: 220px;
  height: 6px;
  left: $gutter-mobile;
  bottom: 200px;

  @include mq($tablet) {
    width: 300px;
    left: $gutter-tablet;
  }

  @include mq($desktop) {
    left: $gutter-desktop;
  }

  @include mq($desktop-med) {
    width: 400px;
  }

  @include mq($desktop-plus) {
    width: 480px;
  }

}//.bar
