.hamburger-menu {
  @include remove-button-styles;
  position: absolute;
  z-index: 999;
  top: 3px;
  right: 0;
  width: 55px;
  height: 70px;

  @include mq($tablet) {
    display: none;
    width: 92px;
    height: 90px;
  }

  @include mq($desktop) {
    width: 112px;
    top: 40px;
  }

  .icon {
    display: block;
    width: 25px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    @include transition;

    @include mq($tablet) {
      width: 32px;
      height: 20px;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $black;
      opacity: 1;
      left: $gutter-mobile;
      transform: rotate(0deg);
      @include transition;
      @include fade-in;

      @include mq($tablet) {
        left: 30px;
        height: 3px;
      }

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
        background: transparent;

        @include mq($tablet) {
          top: 8px;
        }

      }

      &:nth-child(4) {
        top: 10px;

        @include mq($tablet) {
          top: 14px;
        }

      }

    }//span

  }//.icon

  /**
   * Close
   */
  &.active {

    .icon {

      span {
        @include hide-text;

        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          background: $black;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          background: $black;
        }

        &:nth-child(4) {
          display: none;
        }

      }//span

    }//.icon

  }//&.close

}//.mobile-menu-toggle
