.buy-button {
  width: 60px;
  height: 60px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $white;
  color: $polo-blue;
  font-size: 18px;
  text-transform: uppercase;
  font-family: $pt-sans;
  letter-spacing: 1px;
  @include transition;

  @include mq($tablet) {
    width: 70px;
    height: 70px;
    font-size: 20px;
  }

  @include mq($desktop) {
    width: 80px;
    height: 80px;
    font-size: 22px;
  }

  &:hover {
    background: $polo-blue;
    color: $white;
    text-decoration: none;
  }
}