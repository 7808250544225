.about {

  h1, h2 {
    color: $nepal;
    text-transform: uppercase;
    font-family: $pt-sans;
    font-size: 18px;
    letter-spacing: 0.025em;
  }

  h3 {
    font-style: italic;
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }

  section {
    @include mq($mobile) {
      @include spacing(margin);
    }

    @include mq($desktop) {
      @include spacing(margin, bottom);
      padding-left: rem(100);
      padding-right: 20%;
    }

  }


}