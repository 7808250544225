// Layout Max Width
$layout-max-width: 1360px;

// Layout Grid Columns
$layout-columns: 24;

// Layout Side Gutters
$gutter-mobile: 15px;
$gutter-tablet: 30px;
$gutter-desktop: 100px;

// Layout Top Padding
$hdr-height-mobile: 70px;
$hdr-height-tablet: 90px;
$hdr-height-desktop: 160px;
