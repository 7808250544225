.artwork {

  section {
    display: grid;

    @include mq($mobile) {
      @include spacing(margin);
      grid-gap: 36px;

      .pane-1 { padding-right: 20%; }
    }

    @include mq($tablet) {
      grid-template-columns: .8fr 1fr;
      grid-gap: 8%;
    }

    @include mq($desktop) {
      @include spacing(margin, bottom);
      padding-left: rem(100);
      padding-right: 9%;
    }

    img { max-width: 100%; }
  }

}